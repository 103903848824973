import { HuddleGroup } from "../_types";

export const greetingMessage = (firstName: string): string => {
  let msg = "";
  const day = new Date();
  const hr = day.getHours();
  if (hr >= 0 && hr <= 12) {
    msg = `Good Morning, ${firstName}!`;
  } else if (hr > 12 && hr <= 18) {
    msg = `Good Afternoon, ${firstName}!`;
  } else if (hr > 18) {
    msg = `Good Evening, ${firstName}!`;
  }
  return msg;
};

export function addCommas(num: number) {
  // Convert the number to a string
  let numStr = num.toString();

  // Check if the number is greater than 1000
  if (num >= 1000) {
    // Split the string into parts before and after the decimal point
    const parts = numStr.split(".");

    // Add commas to the whole number part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Rejoin the parts into a single string
    numStr = parts.join(".");
  }

  // Return the modified string
  return numStr;
}

export function getTodayDate() {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
  const dd = String(today.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}

export function getFormattedDate(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long", // e.g., Wednesday
    year: "numeric", // e.g., 2024
    month: "long", // e.g., March
    day: "numeric", // e.g., 8
    hour: "numeric", // e.g., 4
    minute: "numeric", // e.g., 30
    hour12: true, // Use 12-hour clock format
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
}

export function stripLink(text: string) {
  const regex = /<<([^:]*):[^>]*>>/g;
  return text.replace(regex, "$1");
}

export function camelCaseToTitle(text: string) {
  // Split the text at uppercase letters and join with a space
  const withSpaces = text.replace(/([A-Z])/g, " $1");

  // Capitalize the first letter of each word
  const titleCase = withSpaces
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return titleCase;
}

export const getInitials = (name: string): string => {
  if (!name) return "";

  const words = name.trim().split(" ");
  if (words.length === 1) {
    // If it's a single word (e.g., "John"), return the first letter
    return words[0].charAt(0).toUpperCase();
  }

  // Otherwise, take the first letter of the first and last word
  const firstInitial = words[0].charAt(0).toUpperCase();
  const lastInitial = words[words.length - 1].charAt(0).toUpperCase();

  return `${firstInitial}${lastInitial}`;
};

export const getLastNameInitial = (name: string): string => {
  if (!name) return "";

  const words = name.trim().split(" ");

  if (words.length === 1) {
    // If there's only one name, just return it (e.g., "Mary")
    return words[0];
  }

  const firstAndMiddleNames = words.slice(0, -1).join(" "); // Join all but the last name
  const lastNameInitial = words[words.length - 1].charAt(0).toUpperCase();

  return `${firstAndMiddleNames} ${lastNameInitial}.`;
};

export const formatDateTime = (dateTimeString: string): string => {
  // Split the dateTime string into date and time parts
  const [datePart, timePart] = dateTimeString.split("T");
  const [, month, day] = datePart.split("-"); // Skipping the year since it's not used
  const [hour24, minutes] = timePart.split(":");

  // Array of short month names
  const monthNames = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sep.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];

  // Convert 24-hour time to 12-hour format
  let hours = parseInt(hour24, 10);
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Handle 0 as 12 in 12-hour format

  // Format the result as `Month Day at hh:mmam/pm`
  return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)} at ${hours}:${minutes}${period}`;
};

export const getHuddleTimeDisplay = (huddle: HuddleGroup) => {
  const localHuddleTime = new Date(huddle.localHuddleTime);
  const timeDisplay = `${localHuddleTime.getHours() % 12 || 12}:${
    localHuddleTime.getMinutes() < 10 ? "0" : ""
  }${localHuddleTime.getMinutes()}${
    localHuddleTime.getHours() >= 12 ? "pm" : "am"
  }`;
  return timeDisplay;
};

export function parseDateTimeStringTo12Hour(dateTimeString: string): string {
  const date = new Date(dateTimeString);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format and handle midnight (0 -> 12)

  const minutesStr = minutes < 10 ? `0${minutes}` : minutes.toString(); // Ensure two-digit minutes
  return `${hours}:${minutesStr}${period}`;
}
